import { gql } from '@apollo/client';


export const getTransactionsWithPendingStatusGQL = gql`
  query getTransactionsWithPendingStatus($limit:Int!,$page:Int!,$sortBy: SortCriterion,$filter: PendingTransactionFilters) {
  getTransactionsWithPendingStatus(paginationOptions: {limit: $limit, page: $page},sortBy:$sortBy,filter:$filter) {
    items{
      id,
      amount,
      transactionAmount,
      transactionStatus,
      transactionCategory,
      merchantName,
      mcc,
      createdAt,
      txnRefNo,
      merchantId,
      user{
        emailId,
        firstName,
        m2pCustomerId,
        lastName
      },
      company {
        registeredName
        id
      }
    },
    meta {
      itemCount,
      totalItems,
      itemsPerPage,
      totalPages,
      currentPage
    }
  }
}

`;

// filterCriteria: {fromAmount: 10, toAmount:200},
export const findCandidateParentTransactionGQL = gql`
  query findCandidateParentTransaction($transactionId: String!,$limit:Int!,$page:Int!,$merchantId:String,$pbiId:String,$mcc:String,$txnMerchantId:String,$fromDate:String,$toDate:String,$searchKeyword:String,$fromAmount: Float, $toAmount: Float) {
	findCandidateParentTransaction(
  	input:{
      transactionId: $transactionId,
      filterCriteria:{merchantId:$merchantId,pbiId:$pbiId,mcc:$mcc,txnMerchantId:$txnMerchantId,fromDate:$fromDate,toDate:$toDate,searchKeyword:$searchKeyword,fromAmount: $fromAmount, toAmount: $toAmount},
      pagination: {limit: $limit, page: $page},    
      sortBy: {field: "createdAt", direction: ASC}
    }  
  ){
    items{
      id,
      createdAt,
      fee,
      merchantId,
      merchantName,
      originalTxn {
        id,
        createdAt,
        fee,
        merchantId,
        merchantName,
      },
      originalTxnAmount,
      refundTransactions {
        id,
        createdAt,
        fee,
        merchantId,
        merchantName,
        totalRefundAmount,
        transactionAmount,
        txnRefNo,
      },
      retrievalRefNo,
      totalRefundAmount,
      transactionAmount,
      transactionStatus,
      amount,
      mcc,
      transactionCategory,
      txnDate,
      user{
        emailId,
        firstName,
        lastName
      },
      company {
        registeredName
        id
      }
    },
    meta {
      itemCount,
      totalItems,
      itemsPerPage,
      totalPages,
    }
  }
}
`;

export const acceptPendingCreditNotificationTransactionGQL = gql`
  mutation acceptPendingCreditNotificationTransaction(
  $txnId: String!
  $parentTxnId: String!
) {
  acceptPendingCreditNotificationTransaction(
    input: {
      parentTxnId: $parentTxnId
      txnId: $txnId
    }
  )
}
`;