import { gql } from '@apollo/client';

export const getLatestUserEkySessionForSuperAdminGQL = gql`
  query getLatestUserEkySessionForSuperAdmin($userId: String!) {
    getLatestUserEkySessionForSuperAdmin(userId: $userId) {
      userEKycSessionRecord {
        id
        notificationSentToUserAt
        succeeded
        registrationLastSucceededAt
      }
      user {
        id
      }
      userKycDataArr {
        id
        version
        userId
        eKycAadhaarSessionId
        status
        cardIntegration {
          id
          cardIntegratorKey
          cardNetwork
          description
          tenantKey
        }
      }
    }
  }
`;

export const createAndSendEKycLinkBySuperAdminToUserOfAlreadyOnboardedCompanyGQL = gql`
  mutation createAndSendEKycLinkBySuperAdminToUserOfAlreadyOnboardedCompany($userId: String!, $pbiId: String!, $cardIntegrationId: String!, $shouldIssueCard: Boolean! ) {
    createAndSendEKycLinkBySuperAdminToUserOfAlreadyOnboardedCompany(input:{userId: $userId, pbiId: $pbiId, cardIntegrationId: $cardIntegrationId, shouldIssueCard: $shouldIssueCard}) {
      notificationSentToUserAt
      succeeded
    }
  }
`;
export const removeUserFromCompanyGQL = gql`
  mutation removeUserFromCompany(
    $userAccountId: String!
    $companyId: String!
    $notes: String
  ) {
    removeUserFromCompany(
      input:{
        userAccountId: $userAccountId, 
        companyId: $companyId, 
        notes: $notes
      })
  }
`;
export const enableUserForCompanyGQL = gql`
  mutation enableUserForCompany(
    $userAccountId: String!
    $companyId: String!
    $notes: String
  ) {
    enableUserForCompany(
      input:{
        userAccountId: $userAccountId, 
        companyId: $companyId, 
        notes: $notes
      })
  }
`;
export const generateVcipLinkWithUpdatedDetailsGQL = gql`
  mutation generateVcipLinkWithUpdatedDetails(
      $userId: String!
      $dateOfBirth: String
      $firstName: String
      $middleName: String
      $lastName: String
      
      $currentAddress: String
      $currentPinCode: String
      $currentCity: String
      $currentState: String

      $permanentAddress: String
      $permanentPinCode: String
      $permanentCity: String
      $permanentState: String
    ) {
    generateVcipLinkWithUpdatedDetails(input:{
      userId: $userId, 
      dateOfBirth: $dateOfBirth
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      currentAddress: $currentAddress
      currentPinCode: $currentPinCode
      currentCity: $currentCity
      currentState: $currentState
      permanentAddress: $permanentAddress
      permanentPinCode: $permanentPinCode
      permanentCity: $permanentCity
      permanentState: $permanentState
    }) {
      responseDescription
      qrImage
      vcipLink
      vcipId
    }
  }
`;

export const addDeliveryAddressGQL = gql`
  mutation addDeliveryAddress(
    $id: String
    $addressLine1: String
    $addressLine2: String
    $cityId: Int
    $stateId: Int
    $pincode: Int
    $user: String
  ) {
    addDeliveryAddress(
      userAddress: {
        id: $id
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        cityId: $cityId
        stateId: $stateId
        pincode: $pincode
        user: $user
      }
    )
  }
`;

export const pendingPaymentAndAssociatedCompanyListForUserGQL = gql`
  query pendingPaymentAndAssociatedCompanyListForUser($userAccountId: String!,$companyId: String!) {
    pendingPaymentAndAssociatedCompanyListForUser(userAccountId: $userAccountId,companyId: $companyId) {
      userAccount,
      pendingPaymentCount,
      userCompanyList {
          company {
            id
            registeredName
            companyLimit
            brandName
          }
        }
      }
    }
`;
