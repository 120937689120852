import { gql } from '@apollo/client';

export const addNewCardholderForEKycGQL = gql`
  mutation addNewCardholderForEKyc($input: UserDetailsInputForEKyc) {
    addNewCardholderForEKyc(input: $input) 
  }
`;

export const getLimitsSpecifiedInCardIssuanceRequestGQL = gql`
query getLimitsSpecifiedInCardIssuanceRequest($input: LimitsSpecifiedInCardIssuanceRequestInput!) {
  getLimitsSpecifiedInCardIssuanceRequest(input: $input) {
    monthlyLimit
    dailyLimit
  }
}
`;
export const updateCardholderForEKycGQL = gql`
  mutation updateCardholderForEKyc($input: UserDetailsInputForEKyc) {
    updateCardholderForEKyc(input: $input)
  }
`;

export const updateUserLimitForPbiGQL = gql`
  mutation updateUserLimitForPbi($userId: String!, $pbiId: String!, $monthlyLimit: Float, $dailyLimit: Float, $cardId: String!) {
    updateUserLimitForPbi(input: { userId: $userId, pbiId: $pbiId, monthlyLimit: $monthlyLimit, dailyLimit: $dailyLimit, cardId: $cardId })
  }
`;

export const updateCardholderDetailsGQL = gql`
  mutation updateCardholderDetails($input: UpdateCardholdersDetail) {
    updateCardholderDetails(input: $input)
  }
`;

export const addNewCardholderForPaperKycGQL = gql`
  mutation addNewCardholderForPaperKyc($input: UserDetailsInputForPaperKyc) {
    addNewCardholderForPaperKyc(input: $input) 
  }
`;

export const updateCardholderForPaperKycGQL = gql`
  mutation updateCardholderForPaperKyc($input: UserDetailsInputForPaperKyc) {
    updateCardholderForPaperKyc(input: $input)
  }
`;

export const assignOrRevokeRoleToUserGQL = gql`
  mutation assignOrRevokeRoleToUser($input: AssignRevokeRoleToUserInput!) {
    assignOrRevokeRoleToUser(input: $input)
  }
`;
export const assignFirstCardToUserGQL = gql`
  mutation assignFirstCardToUser($userId: String!, $pbiId: String!, $cardIntegrationId: String!, $alsoIssuePhysicalForm: Boolean!) {
    assignFirstCardToUser(input: {userId: $userId, pbiId: $pbiId, cardIntegrationId: $cardIntegrationId, alsoIssuePhysicalForm: $alsoIssuePhysicalForm})
  }
`;
export const issueAdditionalCardToUserFromMgmtPortalGQL = gql`
  mutation issueAdditionalCardToUserFromMgmtPortal($pbiUserMappingId: String!, $dailyLimit: Float, $monthlyLimit: Float, $fundsSource: CardFundsSource!) {
    issueAdditionalCardToUserFromMgmtPortal(
      input: {
        pbiUserMappingId: $pbiUserMappingId,
        dailyLimit: $dailyLimit,
        monthlyLimit :$monthlyLimit
        fundsSource: $fundsSource
      }
    ) {
      id
      kit {
        id
        kitNo
      }
    }
  }
`;

export const getCompanyLists = gql`
  query getCompaniesForManagementPortal {
    getCompaniesForManagementPortal(filter: {}) {
      page
      company {
        nonDeletedPBIsSortedByDate {
          creditLimit
          companyProductEnabled {
            id
            cards
          }
          productsBundle {
            id
            displayName
          }
        }
        id
        registeredName
        cin
        emailId
        brandName
        companyLimit
        companyBalance
        isActive
        companyVerification
        isNachActive
        nameOnCard
      }
    }
  }
`;

export const editCompanyDetailGQL = gql`
  mutation editCompanyDetail($CompanyDetail: CompanyDetail) {
    editCompanyDetail(company: $CompanyDetail)
  }
`;

export const lockCardBySuperAdminGQL = gql`
  mutation lockCardBySuperAdmin($cardId: ID!) {
    lockCardBySuperAdmin(cardId: $cardId)
  }
`;

export const unlockCardBySuperAdminGQL = gql`
  mutation unlockCardBySuperAdmin($cardId: ID!) {
    unlockCardBySuperAdmin(cardId: $cardId)
  }
`;

export const blockCardBySuperAdminGQL = gql`
  mutation blockCardBySuperAdmin($cardId: ID!) {
    blockCardBySuperAdmin(cardId: $cardId)
  }
`;