import { useQuery, useMutation, ApolloClient, ApolloLink } from '@apollo/client';

import {
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { AppConfig } from '../app.config';
// import { onError } from "@apollo/client/link/error";
const API_URL = process.env.REACT_APP_URL;

export { useQuery }
export { useMutation }

const httpLink = createHttpLink(
  {
    uri: `${API_URL}/graphql`,
    credentials: 'same-origin'
  }
);
export enum GraphQlErrors {
  ACCESSED_WITH_REVOKED_TOKEN = "ACCESSED_WITH_REVOKED_TOKEN",
  UNAUTHORIZED = "Unauthorized",
}
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists

  const accessTokenRaw = sessionStorage.getItem(AppConfig.apiTokenKey);
  //return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      "Authorization": accessTokenRaw ? `Bearer ${accessTokenRaw}` : "",
      "Role": '1', // temp hard coded
    }
  }
});

const interceptorLink = new ApolloLink((operation, forward) => {
  // modify the operation context
  if (operation?.operationName === "refreshToken") {
    const refreshToken = sessionStorage.getItem(AppConfig.refreshTokenKey);
    operation.setContext({
      headers: {
        "Authorization": refreshToken ? `Bearer ${refreshToken}` : "",
        "Role": '1', // temp hard coded will be resolved when SBM access merged
      }
    });
  }

  const accessTokenRaw = sessionStorage.getItem(AppConfig.apiTokenKey);
  if (!accessTokenRaw) {
    window.location.href = '/'
  }

  let date = new Date()
  sessionStorage.setItem(AppConfig.lastActivityKey, String(date.getTime()));

  return forward(operation).map((response: any) => {
    // modify the response object
    if (response?.errors?.length) {
      if (response.errors.some((it: any) => [GraphQlErrors.ACCESSED_WITH_REVOKED_TOKEN, GraphQlErrors.UNAUTHORIZED].includes(it.message))) {
        sessionStorage.clear();
        window.location.reload()
      }
    }

    return response;
  });
});

//https://www.apollographql.com/docs/react/api/link/apollo-link-error/
// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.forEach(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
//       ),
//     );

//   if (networkError) console.log(`[Network error]: ${networkError}`);
// });

export const clients = new ApolloClient(
  {
    link: ApolloLink.from([
      authLink,
      interceptorLink,
      httpLink,
      // errorLink
    ]),
    cache: new InMemoryCache()
  }
);