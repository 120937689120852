import { gql } from '@apollo/client';

export const getPenaltyForCompaniesAppearingInUpcomingStatementsGQL = gql`
  query {
  getPenaltyForCompaniesAppearingInUpcomingStatements {
    companyName
    pbiDisplayName
    dueAmount
    gst
    outstandingAmount
    penaltyAmount
    totalPenalty
  }
}
`;

export const calculateCompanyPenaltiesForUpcomingStatementGQL = gql`
  mutation {
  calculateCompanyPenaltiesForUpcomingStatement(pbiIds: []) {
    pbiId
    penaltyAmount
    totalPenaltyAmount
    totalRepaymentUntilCutOffDate
    outstandingAmountOnCutOffDate
  }
}
`;